import React, { useEffect } from "react";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { Merchandisection } from "./Merchandisection";
import "../landingpage.css";
import { connect } from "react-redux";
import { getMerchandises } from "../../../store/actions/merchandiseAction";

const MerchantpageLayout = ({ merchandises, loading, getMerchandises }) => {
  useEffect(() => {
    getMerchandises({});
  }, [getMerchandises]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSort = (val) => {
    getMerchandises({ sort: val.target.value });
  };
  return (
    <div>
      <Homeheader />
      <Merchandisection merchandises={merchandises} handleSort={handleSort} />
      <HomeFooter />
    </div>
  );
};
const mapStateToProps = ({ merchandise: { loading, merchandises } }) => {
  return {
    loading,
    merchandises,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMerchandises: (payload) => dispatch(getMerchandises(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantpageLayout);
