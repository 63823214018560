import React from "react";
import "./common.css";
import image_url from "../common/images";
import { Link } from "react-router-dom";
import { NAVIGATION } from "../common/constants";
export const Merchantproduct = ({ merchandise }) => {
  return (
    <div className="merchant_class">
      <div className="product_card">
        <img
          className="merchant"
          src={merchandise.image ? merchandise.image : image_url.product}
          alt={merchandise.title}
        />
        <h5 className="text_overflow">{merchandise.title}</h5>
        <h4>£{merchandise.price}</h4>
        <Link to={`${NAVIGATION.PRODUCT_DETAIL}/merchandise/${merchandise.id}`}>
          <button className="product_btn">View Product</button>
        </Link>
      </div>
    </div>
  );
};
