import React ,{useEffect}from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import Homeheader from "../LandingPages/Homeheader";
import { HomeFooter } from "../LandingPages/HomeFooter";

const PaymentSuccess = () => {
  const history = useHistory();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="">
      <Homeheader />
      <Container>
        <Row>
          <Col>
            <div className="open_disclaimer ">
              <div className="disclaimer  ">
                <div className="faq_box">
                  <h1>Payment successfully</h1>
                </div>
                <div className="dilbychance">
                  <button
                    className="product_btn mt-3"
                    onClick={() => history.push("/")}
                  >
                    go to home
                  </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <HomeFooter />
    </div>
  );
};

export default PaymentSuccess;
