export const FETCH_MERCHANDISE_LIST = "FETCH_MERCHANDISE_LIST";
export const SET_MERCHANDISE_LIST = "SET_MERCHANDISE_LIST";
export const RESET_MERCHANDISE_LIST = "RESET_MERCHANDISE_LIST";
export const FETCH_MERCHANDISE_ERROR = "FETCH_MERCHANDISE_ERROR";
export const SET_MERCHANDISE_DETAIL = "SET_MERCHANDISE_DETAIL";
export const FETCH_READING_LIST = "FETCH_READING_LIST";
export const SET_READING_LIST = "SET_READING_LIST";
export const RESET_READING_LIST = "RESET_READING_LIST";
export const FETCH_READING_ERROR = "FETCH_READING_ERROR";
export const SET_READING_DETAIL = "SET_READING_DETAIL";
export const FETCH_BLOG_LIST = "FETCH_BLOG_LIST";
export const SET_BLOG_LIST = "SET_BLOG_LIST";
export const RESET_BLOG_LIST = "RESET_BLOG_LIST";
export const FETCH_BLOG_ERROR = "FETCH_BLOG_ERROR";
export const SET_BLOG_DETAIL = "SET_BLOG_DETAIL";
export const RESET_BLOG_DETAIL = "RESET_BLOG_DETAIL";
export const RESET_READING_DETAIL = "RESET_READING_DETAIL";
export const RESET_MERCHANDISE_DETAIL = "RESET_MERCHANDISE_DETAIL";
export const FETCH_POPURLAR_ERROR = "FETCH_POPURLAR_ERROR";
export const FETCH_POPURLAR_LIST = "FETCH_POPURLAR_LIST";
export const RESET_POPURLAR_LIST = "RESET_POPURLAR_LIST";
export const SET_POPURLAR_LIST = "SET_POPURLAR_LIST";
export const FETCH_GUIDELINE_LIST = "FETCH_GUIDELINE_LIST";
export const FETCH_GUIDELINE_ERROR = "FETCH_GUIDELINE_ERROR";
export const SET_FAQ_LIST = "SET_FAQ_LIST";
export const SET_SHIPPING_POLICY = "SET_SHIPPING_POLICY";
export const SET_RETURN_POLICY = "SET_RETURN_POLICY";
export const SET_DISCLAIMER = "SET_DISCLAIMER";
export const SET_TERM_CONDITION = "SET_TERM_CONDITION";
export const SET_ABOUT_US = "SET_ABOUT_US";
export const INITIALIZE_LOADER = "INITIALIZE_LOADER";
export const RESET_LOADING = "RESET_LOADING";
export const INITIALIZE_FORM = "INITIALIZE_FORM";
export const FORM_SUCCESS = "FORM_SUCCESS";
export const FORM_SUBMITTING = "FORM_SUBMITTING";
export const RESET_FORM_SUBMITTING = "RESET_FORM_SUBMITTING";
export const FETCH_CART_ITEM = "FETCH_CART_ITEM";
export const SET_CART_ITEM = "SET_CART_ITEM";
export const FETCH_CART_ERROR = "FETCH_CART_ERROR";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const FETCH_ORDER_DETAIL = "FETCH_ORDER_DETAIL";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
export const FETCH_ORDER_DETAIL_ERROR = "FETCH_ORDER_DETAIL_ERROR";
export const RESET_ORDER_DETAIL = "RESET_ORDER_DETAIL";
export const RESET_CART_ITEM = "RESET_CART_ITEM";
