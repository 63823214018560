import React, { useEffect } from "react";

import Homeheader from "../Homeheader";
import "../landingpage.css";
import { BannerHome } from "./BannerHome";
import { Blogpanel } from "./Blogpanel";
import { HomeFooter } from "../HomeFooter";
import { HeroSection } from "./HeroSection";
import { PopularReading } from "./PopularReading";
import { Reviews } from "./Reviews";


import { Merchandise } from "./Merchandise";
import { connect } from "react-redux";
import { getPopulars } from "../../../store/actions/popuplarAction";
const HomepageLayout = ({
  getPopulars,
  loading,
  readings,
  merchandises,
  blogs,
}) => {
  useEffect(() => {
    getPopulars({});
  }, [getPopulars]);
  return (
    <div>
      <Homeheader />
    
      <HeroSection />
      <PopularReading readings={readings || []} />
      <Merchandise merchandises={merchandises || []} />
      <Blogpanel blogs={blogs || []} />
      
   
      <HomeFooter />
    </div>
  );
};
const mapStateToProps = ({
  popular: { loading, readings, merchandises, blogs },
}) => {
  return {
    loading,
    readings,
    merchandises,
    blogs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPopulars: (payload) => dispatch(getPopulars(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HomepageLayout);
