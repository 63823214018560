import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProductCard } from "../../Shared/ProductCard";

export const ProductSection = ({ readings, handleSort }) => {
  return (
    <div className="product_bar">
      <Container>
        <Row>
          <Col>
            <div className="pop_read">
              <h5>New Readings</h5>

              <div class="d-flex">
                <Form.Select
                  aria-label="Default select example"
                  onChange={handleSort}
                >
                  <option value={""}>Sort</option>
                  <option value="2">Price High</option>
                  <option value="1">Price Low</option>
                </Form.Select>
                {/* <img
                  src="http://localhost:3000/suntarot_web/assets/images/grid-sort.png"
                  alt="grid sort"
                  className="m-3"
                /> */}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {readings &&
            readings.length > 0 &&
            readings.map((reading) => (
              <Col lg={4} key={reading.id}>
                <ProductCard reading={reading} />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};
