import React from "react";
import { Link } from "react-router-dom";
import { dateToFormat } from "../common/_helper";
import { NAVIGATION } from "../common/constants";
import image_url from "../common/images";

export const BlogBox = ({ blog }) => {
  return (
    <div>
      <Link to={`${NAVIGATION.BLOG_DETAIL}/${blog.id}`}>
        <div className="blog_open">
          <div className="blog_content">
            <img
              src={
                blog.image
                  ? blog.image
                  : "http://suntarot.emeraldsuntarot.com/suntarot/assets/images/blog_image.png"
              }
              alt={blog.header}
            />
            <div className="blog_c">
              <span>Article</span>
              <h5 className="blog_Tflow">{blog.header}</h5>
              <p className="myblog_p">{blog.discription}</p>
            </div>
          </div>
          <div className="blog_writer">
            <div className="blog_wri">
              <img
                src={image_url.logo}
                alt=""
              />
            </div>
            <div className="writer_blog">
              <span>Suntarot Emerald Team</span>
              <h5>
                {blog.publish_date &&
                  dateToFormat(blog.publish_date, "MMM DD, YYYY")}{" "}
             
              </h5>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
