import React,{useEffect} from "react";
import { Form, Spinner } from "react-bootstrap";
import "./auth.css";

import image_url from "../common/images";
import Homeheader from "../LandingPages/Homeheader";
import { sendContact } from "../../store/actions/authAction";
import { connect } from "react-redux";
import { Formik, ErrorMessage, Field } from "formik";
import TextError from "../common/TextError";
import { contactValidation } from "../common/validation";
const Contactus = ({ sendContact, submitting }) => {
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    sendContact(values, resetForm);
    setSubmitting(false);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
      <div className="login_panel">
        <div className="login_form">
          <Formik
            initialValues={{
              email: "",
              name: "",
              message: "",
              order_number: "",
            }}
            validationSchema={contactValidation}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="logo_box">
                  <img className="log_img" src={image_url.logo} alt="" />
                </div>
                <div className="title_box">
                  <h5>Get in touch</h5>
                  <p>
                    Remember to check out our FAQ page, as your question may
                    already be answered there.
                  </p>
                </div>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>

                  <Field
                    type="text"
                    placeholder="Your name"
                    className="form-control"
                    name="name"
                  />
                  <ErrorMessage name="name" component={TextError} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Field
                    type="email"
                    placeholder="Your email"
                    className="form-control"
                    name="email"
                  />
                  <ErrorMessage name="email" component={TextError} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Order Number</Form.Label>

                  <Field
                    type="text"
                    placeholder=""
                    className="form-control"
                    name="order_number"
                  />
                  
                  <Form.Text
                    style={{ color: "#BEAFE2" }}
                    className="text-muted"
                  >
                    If you are enquiring about an order, please include your
                    order number.
                  </Form.Text>
                  <ErrorMessage name="order_number" component={TextError} />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Enter Message</Form.Label>

                  <Field
                    as="textarea"
                    placeholder="description"
                    className="form-control"
                    name="message"
                    rows={4}
                  />
                  <ErrorMessage name="message" component={TextError} />
                </Form.Group>
                <button
                  type="submit"
                  className="product_btn d-block w-100 pt-4 pb-4"
                  disabled={submitting}
                >
                  {submitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <span className="contact_btn">Submit</span>
                  )}
                </button>
              </Form>
            )}
          </Formik>
        </div>
        <div className="side_img">
          <img src={image_url.banner} alt="" />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendContact: (payload, resetForm) =>
      dispatch(sendContact(payload, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contactus);
