import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

import { BlogBox } from "../../Shared/BlogBox";

const BlogSection = ({ blogs, handleSort }) => {
  return (
    <div className="product_bar blogbar">
      <Container>
        <Row>
          <Col>
            <div className="blog_row">
              <h5>Latest Blogs</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa
                libero labore natus atque, ducimus sed.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="pop_read blog__sort">
              <Form.Select
                aria-label="Default select example"
                onChange={handleSort}
              >
                <option value={""}>Sort</option>
                <option value="2">Ascending</option>
                <option value="1">Descending</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          {blogs &&
            blogs.length > 0 &&
            blogs.map((blog) => (
              <Col lg={4} key={blog.id}>
                <BlogBox blog={blog} />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default BlogSection;
