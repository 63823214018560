import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_POPURLAR_ERROR,
  FETCH_POPURLAR_LIST,
  SET_POPURLAR_LIST,
} from "../common/types";
import { toast } from "react-toastify";
export const getPopulars = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_POPURLAR_LIST });
    const { data } = await AXIOS.get(APIs.POPULAR_LIST);

    if (data.status) {
      dispatch({
        type: SET_POPURLAR_LIST,
        payload: {
          readings: data.data.readings || [],
          merchandises: data.data.merchandise || [],
          blogs: data.data.blogs || [],
        },
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_POPURLAR_ERROR,
      payload: err,
    });
  }
};
