import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProductCard } from "../../Shared/ProductCard";
export const PopularReading = ({ readings }) => {
  return (
    <div className="popular_read">
      <Container>
        <Row>
          <Col>
            <div className="pop_read">
              <h5>Popular Readings</h5>
              <Link to="/shop/reading">
                <span>See everything</span>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          {readings &&
            readings.length > 0 &&
            readings.map((reading) => (
              <Col lg={4} key={reading.id}>
                <ProductCard reading={reading} />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};
