import React, { useState } from "react";
import Homeheader from "../../LandingPages/Homeheader";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { HomeFooter } from "../../LandingPages/HomeFooter";
import { CartItem } from "./CartItem";
import {
  deleteCartItem,
  getCartItemList,
} from "../../../store/actions/cartAction";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { REMOVE_CART_ITEM } from "../../../store/common/types";
import SiteLoader from "../../Shared/SiteLoader/SiteLoader";

const ShoppingCart = ({ loading, cart_lists, getCartItemList, removeItem }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [checkouts, setCheckouts] = useState([]);
  const [total, setTotal] = useState(0);
  const history = useHistory();
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  useEffect(() => {
    getCartItemList({
      guest_userid: cookies.guest_userid,
    });
  }, [getCartItemList]);

  useEffect(() => {
    if (cart_lists) {
      let data = [];
      cart_lists.forEach((cart) => {
        if (cart.Merchandis) {
          data.push({
            id: cart.Merchandis.id,
            price: cart.Merchandis.price,
          });
        } else if (cart.Reading) {
          data.push({
            id: cart.Reading.id,
            price: cart.Reading.price,
          });
        }
      });

      setCheckouts([...data]);
      const sum = data.reduce((total, item) => total + Number(item.price), 0);
      setTotal(sum);
    }
  }, [cart_lists]);

  const removeCartItem = (cartId) => {
    deleteCartItem(cartId).then((res) => {
      if (res) removeItem(cartId);
    });
  };

  return (
    <div>
      <Homeheader />
      <div className="shop_cart_box">
        {loading ? <SiteLoader /> : null}
        <Container>
          <Row>
            <Col lg={8}>
              <h5 className="shop_head">Shopping Cart</h5>
              {cart_lists &&
                cart_lists.length > 0 &&
                cart_lists.map((cart_list) => (
                  <CartItem
                    key={cart_list.id}
                    cart_list={cart_list}
                    removeCartItem={removeCartItem}
                  />
                ))}
              {cart_lists && cart_lists.length === 0 && (
                <h1 style={{ color: "white", textAlign: "center" }}>
                  Cart Empty
                </h1>
              )}
            </Col>
            <Col lg={4} className="pt-5">
              {checkouts && checkouts.length > 0 && (
                <div className="summery_box">
                  <h5>Order Summary</h5>
                  {checkouts &&
                    checkouts.map((checkout) => (
                      <p key={checkout.id}>
                        <span>Subtotal</span>
                        <span>£{checkout.price}</span>
                      </p>
                    ))}

                  <p className="total_order">
                    <span>Total</span>
                    <span>£{total}</span>
                  </p>

                  <button
                    className="product_btn d-block w-100"
                    onClick={() => {
                      localStorage.setItem("total_price", total);
                      history.push("/shoppingcart/checkout");
                    }}
                  >
                    Checkout
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      <HomeFooter />
    </div>
  );
};
const mapStateToProps = ({ cart: { loading, cart_lists } }) => {
  return {
    loading,
    cart_lists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCartItemList: (payload) => dispatch(getCartItemList(payload)),
    removeItem: (cartId) =>
      dispatch({ type: REMOVE_CART_ITEM, payload: { cartId } }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);
