import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getToc } from "../../../store/actions/guidelineAction";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { HomeHeader2 } from "../HomeHeader2";

export const TermsCondition = () => {
  const { termAndCondition } = useSelector((state) => state.guideline);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getToc());
  }, [dispatch]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div>
      <Homeheader />
      <HomeHeader2 />
      <Container>
        <Row>
          <Col>
            <div className="open_disclaimer">
              <div className="disclaimer terms">
                <h5>Terms and Conditions </h5>
                <div className="condition">
                  <div className="terms_box">
                    <h4>{termAndCondition.header}</h4>
                    <p className="formatted__description">
                      {termAndCondition.discription}
                    </p>
                  </div>
                  {/* <div className="terms_box new_op">
                    <h4>Application</h4>
                    <p>
                      1. These Terms and Conditions will apply to the purchase
                      of the services and goods by you (the Customer or you). We
                      are Gemma Barrtrading as Emerald Sun Tarot of Flat 11, 39
                      Anton Drive, DD5 3DS with email address
                      bookings@emeraldsun-tarot.com ; (the Supplier or us or
                      we).
                    </p>
                    <p>
                      2. There are the terms on which we sell all Services to
                      you. Before placing an order on the Website, you will be
                      asked to agree to these Terms and Conditions by clicking
                      on the button marked ‘I Accept’. If you do not click on
                      the button, you will not be able to complete your Order.
                      You can only purchase the Services and Goods from the
                      Website if you are eligible to enter into a contract and
                      are at least 18 years old.
                    </p>
                  </div>{" "}
                  <div className="terms_box new_op">
                    <h4>Interpretation</h4>
                    <p>
                      3. Consumer means an individual acting for purposes which
                      are wholly or mainly outside his or her trade, business,
                      craft or profession;
                    </p>
                    <p>
                      4. Contact means the legally-binding agreement between you
                      and us for the supply of the Services;
                    </p>
                    <p>
                      5. Delivery Location means the Supplier’s premises or
                      other location where the Services are to be supplied, as
                      set out in the Order;
                    </p>
                    <p>
                      6. Durable Medium means paper or email, or any other
                      medium that allows information to be addressed personally
                      to the recipient, enables the recipient to store the
                      information in a wat accessible for future reference for a
                      period that is long enough for the purposes of the
                      information, and allows the unchanged reproduction of the
                      information stored;
                    </p>
                    <p>
                      7. Goods means any goods that we supply to you with the
                      Services, of the number and description as set out in the
                      Order;
                    </p>
                    <p>
                      8. Order means the Customer’s order for the Services from
                      the Supplier as submitted following the step by step
                      process set out on the Website;
                    </p>
                    <p>
                      9. Privacy Policy means the terms which set out how we
                      will deal with confidential and personal information
                      received from you via the Website;
                    </p>
                    <p>
                      10. Services means the services advertised on the Website,
                      including any Goods, of the number and description set out
                      in the Order;
                    </p>
                    <p>
                      11. Website means our website www.emeraldsun-tarot.com on
                      which the Services are advertised.
                    </p>
                  </div>
                  <div className="terms_box new_op">
                    <h4>Services</h4>
                    <p>
                      12. The description of the Services and any Goods is as
                      set out in the Website, catalogues, brochures or other
                      form of advertisement. Any description is for illustrative
                      purposes only and there may be small discrepancies in the
                      size and colour of any Goods suppled.
                    </p>
                    <p>
                      13. In the case of Services and any Goods made to your
                      special requirements, it is your responsibility to ensure
                      that any information or specification you provide is
                      accurate.
                    </p>
                    <p>
                      14. All Services which appear on the Website are subject
                      to availability.
                    </p>
                    <p>
                      15. We can make changes to the Services which are
                      necessary to comply with any applicable law or safety
                      requirement. We will notify you of these changes.
                    </p>
                  </div>
                  <div className="terms_box new_op">
                    <h4>Customer Responsibilities</h4>
                    <p>
                      16. You must co-operate with us in all matters relating to
                      the Services, provide us and our authorized employees and
                      representatives with all information required to perform
                      the Services.
                    </p>
                    <p>
                      17. Failure to comply with the above is a Customer default
                      which entitles us to suspend performance of the Services
                      until you remedy it or if you fail to remedy it following
                      our request, we can terminate the Contract with immediate
                      effect on written notice to you.
                    </p>
                  </div>
                  <div className="terms_box new_op">
                    <h4>Basis of Sale</h4>
                    <p>
                      20. The description of the Services and any Goods in our
                      website does not constitute a contractual offer to sell
                      the Services or Goods. When an Order has been submitted to
                      the Website, we can reject it for any reason, although we
                      will try to tell you the reason without delay.
                    </p>
                    <p>
                      21. The Order process is set out on the Website. Each step
                      allows you to check and amend any errors before submitting
                      the Order. It is your responsibility to check that you
                      have used the ordering process correctly.
                    </p>
                    <p>
                      22. A Contract will be formed for the Services only when
                      you receive an email from us confirming the Order (Order
                      Confirmation). You must ensure that the Order Confirmation
                      is complete and accurate and inform us immediately of any
                      errors. We are not responsible for any inaccuracies in the
                      Order placed by you. By placing an Order you agree to us
                      giving you confirmation of the Contract by means of an
                      email with all information in it (ie the Order
                      Confirmation). You will receive the Order Confirmation
                      within a reasonable time after making the Contract, but in
                      any event not later than the delivery of any Goods
                      supplied under the Contract, and before performance begins
                      of any of the Services.
                    </p>
                    <p>
                      23. No variation of the Contract, whether about
                      description of the Services, Fees or otherwise, can be
                      made after it has been entered into unless the variation
                      is agreed by the Customer and Supplier in writing.
                    </p>
                    <p>
                      24. We intend that these Terms and Conditions apply only
                      to a Contract entered by you as a Consumer. If this is not
                      the case, you must tell us, so that we can provide you
                      with a different contract with terms which are more
                      appropriate for you and which might, in some respects, be
                      better for you, eg by giving you rights as a business.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <HomeFooter />
    </div>
  );
};
