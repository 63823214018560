import {
  FETCH_MERCHANDISE_ERROR,
  FETCH_MERCHANDISE_LIST,
  RESET_MERCHANDISE_DETAIL,
  RESET_MERCHANDISE_LIST,
  SET_MERCHANDISE_DETAIL,
  SET_MERCHANDISE_LIST,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  merchandises: [],
  detail: {},
};

const merchandiseReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MERCHANDISE_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_MERCHANDISE_LIST:
      return { ...state, merchandises: [...action.payload], loading: false };
    case RESET_MERCHANDISE_LIST:
      return {
        ...state,
        merchandises: [],
      };
    case FETCH_MERCHANDISE_ERROR:
      return {
        ...state,
        merchandises: [],
        loading: false,
        error: action.payload,
      };
    case SET_MERCHANDISE_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case RESET_MERCHANDISE_DETAIL:
      return {
        ...state,
        detail: {},
      };
    default:
      return state;
  }
};

export default merchandiseReducer;
