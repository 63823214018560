import React, { useState } from "react";
import "./orderpage.css";
import image_url from "../common/images";
import { RESET_ORDER_DETAIL } from "../../store/common/types";
import { useEffect } from "react";
import { getOrderDetails } from "../../store/actions/cartAction";
import { useCookies } from "react-cookie";
import { connect } from "react-redux";
import SiteLoader from "../Shared/SiteLoader/SiteLoader";
import { useHistory } from "react-router-dom";
const Orderpage = ({ resetDetail, loading, details, getOrderDetails }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [total, setTotal] = useState(0);
  const history = useHistory();
  useEffect(() => {
    getOrderDetails({ guest_userid: cookies.guest_userid });
    return () => resetDetail();
  }, []);

  useEffect(() => {
    if (details && details.data && details.data.length) {
      const sum = details.data.reduce(
        (total, item) => total + Number(item.price),
        0
      );
      setTotal(sum);
    }
  }, [details]);

  return (
    <div className="order_page">
      {loading ? <SiteLoader /> : null}
      <div className="order_details">
        <div className="text-center">
          <img className="mb-4 m-auto" src={image_url.logo} alt="" />
        </div>
        <p className="para text-center">Order Details</p>
        <div className="sub_total_bag mb-3">
          <h5>User Info</h5>
          <p className="sub">
            <span>Full Name</span>
            <span>{details.full_name}</span>
          </p>
          <p className="sub">
            <span>Email</span>
            <span>{details.email}</span>
          </p>
          <p className="sub open_sun">
            <span> Phone Number</span>
            <span>{details.phone_no}</span>
          </p>
        </div>
        <div className="item_op">
          {details &&
            details.data &&
            details.data.length > 0 &&
            details.data.map((item, i) => (
              <div className="flex_check mb-3" key={`item_${i}`}>
                <div className="pup_up">
                  <img
                    src={item.image ? item.image : image_url.product}
                    alt=""
                  />
                </div>
                <div className="pupup_details">
                  <h5>{item.title}</h5>
                  <p>£{item.price}</p>
                </div>
              </div>
            ))}
        </div>
        <div className="sub_total_bag">
          <h5>Order Info</h5>
          <p className="sub">
            <span>Order Date:</span>
            <span>{details.date}</span>
          </p>
          <p className="sub">
            <span>Order Number:</span>
            <span>{`order_${details.id}`}</span>
          </p>
          <p className="sub ">
            <span> Payment:</span>
            <span>****{details.card_no}</span>
          </p>
          <p className="sub ">
            <span> Address:</span>
            <span>
              {details.address}, {details.city}, {details.state},{" "}
              {details.postal}
            </span>
          </p>
        </div>
        <div className="sub_total_bag">
          <p className="sub open_sun">
            <span>Subtotal</span>
            <span>£{total}</span>
          </p>

          <p className="one_end">
            <span>Order total</span>
            <span>£{total}</span>
          </p>
        </div>
        <div className="margin__auto">
          {" "}
          <button
            className="product_btn mt-3 "
            onClick={() => history.push("/")}
          >
            Go To Home
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ order: { loading, details } }) => {
  return {
    loading,
    details,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetails: (payload) => dispatch(getOrderDetails(payload)),
    resetDetail: () => dispatch({ type: RESET_ORDER_DETAIL }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Orderpage);
