import React from "react";
import image_url from "../../common/images";
import { Icon } from "@iconify/react";
export const CartItem = ({ cart_list, removeCartItem }) => {
  return (
    <div>
      <div className="shop_product">
        <div className="cart_box">
          <Icon
            className="close"
            icon="clarity:window-close-line"
            color="#d1d5db"
            onClick={() => removeCartItem(cart_list.id)}
          />
          {cart_list.Merchandis && (
            <>
              <div className="product_img">
                <img
                  src={
                    cart_list.Merchandis.image
                      ? cart_list.Merchandis.image
                      : image_url.product
                  }
                  alt=""
                />
              </div>
              <div className="product_name">
                <h5>{cart_list.Merchandis.title}</h5>
                <p className="price">£{cart_list.Merchandis.price}</p>
                <div className="time_date">
                  <Icon icon="uil:calender" color="#6b7280" />
                  <span className="timebox">
                    {/* <span>9:00am Tuesday,</span> */}
                    <span>{cart_list.date}</span>
                  </span>
                </div>

                <div className="stock_status">
                  <Icon icon="bi:check" color="#22c55e" />
                  <span>In stock</span>
                </div>
              </div>
            </>
          )}
          {cart_list.Reading && (
            <>
              <div className="product_img">
                <img
                  src={
                    cart_list.Reading.image
                      ? cart_list.Reading.image
                      : image_url.product
                  }
                  alt=""
                />
              </div>
              <div className="product_name">
                <h5>{cart_list.Reading.title}</h5>
                <p className="price">£{cart_list.Reading.price}</p>
                <div className="time_date">
                  <Icon icon="uil:calender" color="#6b7280" />
                  <span className="timebox">
                    {/* <span>9:00am Tuesday,</span> */}
                    <span>{cart_list.date}</span>
                  </span>
                </div>

                <div className="stock_status">
                  <Icon icon="bi:check" color="#22c55e" />
                  <span>In stock</span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
