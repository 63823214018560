const BASE_URL = "https://new.emeraldsun-tarot.com/backend/api/";
const APIs = {
    READING_LIST: "/viewUserReadingsList",
    READING_DETAIL: "/userViewReadingsDetail",
    BLOG_LIST: "/userBlogList",
    BLOG_DETAIL: "/BlogDetail",
    MERCHANDISE_LIST: "/userMerchandiseList",
    MERCHANDISE_DETAIL: "/userViewMerchandiseDetail",
    POPULAR_LIST: "/bloglistwithpagination",
    FAQ_LIST: "/guidelinesList",
    DISCLAIMER: "/disclaimerList",
    TERMS_CONDITION: "/termAndConditionList",
    SHIPPING_POLICY: "getShippingPolicy",
    RETURN_POLICY: "/getReturnPolicy",
    GET_ABOUT_US: "/AboutUsList",
    ADD_CONTACT_US: "/addContactUs",

    ADD_READING_TO_BAG: "/addReadingCart",
    ADD_MERCHANDISE_TO_BAG: "/addMerchandiseCart",
    SHOPPING_CART_LIST: "/ShoppingCartList",
    REMOVE_CART_ITEM: "/ShoppingRemove",
    ADD_RECHECK: "/addRecheck",
    CREATE_PAYMENT: "/webStripeAddCart",
    GET_ORDER_DETAIL: "/WebUserOrderList",
};

export { BASE_URL, APIs };