import React, { useState } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  addMerchandiseToBag,
  addReadingToBag,
} from "../../../store/actions/cartAction";

export const FormProduct = ({ page, reading, merchandise }) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [relationship, setRelationship] = useState("1");
  const [Would_you_like, setWouldYouLike] = useState("1");
  const [additionInfo, setAdditionInfo] = useState("");
  const dispatch = useDispatch();
  const { submitting } = useSelector((state) => state.form);
  const resetForm = () => {
    setAdditionInfo("");
    setRelationship("1");
    setWouldYouLike("1");
  };

  const addToBag = () => {
    let payload = {
      relationship: relationship,
      Would_you_like: Would_you_like,
      guest_userid: cookies.guest_userid,
      additional_info: additionInfo,
    };
    if (page === "reading") {
      payload.reading_id = reading.id;
      dispatch(addReadingToBag({ ...payload }, resetForm));
    } else if (page === "merchandise") {
      payload.merchandise_id = merchandise.id;
      dispatch(addMerchandiseToBag({ ...payload }, resetForm));
    }
  };

  return (
    <div className="name_form">
      <Container>
        <Row>
          <Col lg={6}>
            {page === "reading" && (
              <>
                {" "}
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Are you currently in a relationship?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Check
                    type={"radio"}
                    value="1"
                    aria-label="yes"
                    label="Yes"
                    checked
                    name="relationship"
                    onChange={(e) => setRelationship(e.target.value)}
                  />
                  <Form.Check
                    type={"radio"}
                    value="2"
                    aria-label="yes"
                    label="No"
                    name="relationship"
                    onChange={(e) => setRelationship(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Would you like to be emailed when our new interactive
                    reading App is available for download?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>

                  <Form.Check
                    aria-label="yes"
                    label="Yes"
                    type={"radio"}
                    value="1"
                    name="Would_you_like"
                    checked
                    onChange={(e) => setWouldYouLike(e.target.value)}
                  />
                  <Form.Check
                    aria-label="yes"
                    label="No"
                    type={"radio"}
                    value="2"
                    name="Would_you_like"
                    onChange={(e) => setWouldYouLike(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Additional Info</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Only fill in if there is additional information you had like to provide."
                    rows={6}
                    value={additionInfo}
                    onChange={(e) => setAdditionInfo(e.target.value)}
                  />
                </Form.Group>
              </>
            )}

            <button
              onClick={addToBag}
              className="product_btn"
              disabled={submitting}
            >
              {submitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Add to bag"
              )}
            </button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
