import React, { useState } from "react";
import { Navbar, Container, Nav, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import image_url from "../common/images";
const Homeheader = () => {
  const { cart_lists } = useSelector((state) => state.cart);
  // const [color, setColor] = useState(false);
  // const changecolor = () => {
  //   if (window.scrollY >= 90) {
  //     setColor(true)

  //   } else {
  //     setColor(false)
  //   }
  // }
  // window.addEventListener ('scroll',changecolor)
  return (
    <div className="header">
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="#">
            <img src={image_url.logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0">
              {/* activeClassname="active" */}
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>

              <Dropdown>
                <Dropdown.Toggle
                  className="shop_dr"
                  variant="success"
                  id="dropdown-basic"
                >
                  Shop
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Nav.Link as={Link} to="/shop/reading" href="">
                    Readings
                  </Nav.Link>
                  <Nav.Link as={Link} to="/shop/merchandise" href="">
                    Merchandise
                  </Nav.Link>
                </Dropdown.Menu>
              </Dropdown>
              <Nav.Link as={Link} to="/rechecks">
                Rechecks
              </Nav.Link>
              <Nav.Link as={Link} to="/blog">
                Blog
              </Nav.Link>
              {/* <Nav.Link as={Link} to="/review">
                Reviews
              </Nav.Link> */}

              <Nav.Link as={Link} to="/contactus">
                Contact Us
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto my-2 my-lg-0 active">
              <Nav.Link
                as={Link}
                className={`icon_shopping ${
                  cart_lists.length > 0 && "cart__fill"
                }`}
                to="/shoppingcart"
              >
                <img src={image_url.shopping} alt="" />
                {cart_lists.length > 0 && <span>({cart_lists.length})</span>}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default withRouter(Homeheader);
