import React, { useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { HomeHeader2 } from "../HomeHeader2";


const DateandSelect = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <div className="">
      <Homeheader />
      <HomeHeader2 />
      <Container>
        <Row>
          <Col>
            <div className="open_disclaimer ">
              <div className="disclaimer date_check ">
                <div className="faq_box">
                  <h5>Select date and time</h5>
                  <p>Rechecks apply for fertility readings only. At present; rechecks are free of charge ONLY if your previous prediction passed with no success.</p>
                  <p>If your current cycle began in the month predicted, you would not be eligible for a recheck until a new cycle begins outwith the predicted month. If you do not have regular cycles due to a condition such as PCOS, you will be entitled to a recheck 4 weeks after the predicted month has passed (28th of the following month).</p>
                </div>
                <div className="dilbychance">
                  <Form className="mt-5 disclaimer">

                  <Form.Group  controlId="formBasicEmail">
                    <Row>
                      <Col lg={12}> 
                      
                     <Form.Label>Description</Form.Label>
                     <Form.Control className="mb-3"  as="textarea" rows={6} />
                     <div style={{textAlign:"right"}}>
                <button className='product_btn mb-0'>Set a Schedule</button> 
                </div>
                      </Col>
                      
                    </Row>
                  </Form.Group>


                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <HomeFooter />
    </div>
  );
};



export default DateandSelect;
