import {
  FETCH_GUIDELINE_LIST,
  FETCH_GUIDELINE_ERROR,
  SET_FAQ_LIST,
  SET_SHIPPING_POLICY,
  SET_RETURN_POLICY,
  SET_DISCLAIMER,
  SET_TERM_CONDITION,
  SET_ABOUT_US,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  faq_lists: [],
  termAndCondition: {},
  disclaimer: {},
  shipping_policy: {},
  return_policy: {},
  about_us: {},
};
const guidelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GUIDELINE_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_FAQ_LIST:
      return { ...state, faq_lists: [...action.payload], loading: false };
    case SET_DISCLAIMER:
      return { ...state, disclaimer: action.payload, loading: false };
    case SET_TERM_CONDITION:
      return { ...state, termAndCondition: action.payload, loading: false };
    case SET_RETURN_POLICY:
      return { ...state, return_policy: action.payload, loading: false };
    case SET_SHIPPING_POLICY:
      return { ...state, shipping_policy: action.payload, loading: false };
    case SET_ABOUT_US:
      return { ...state, about_us: action.payload, loading: false };
    case FETCH_GUIDELINE_ERROR:
      return {
        ...state,
        faq_lists: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
export default guidelineReducer;
