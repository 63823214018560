import React from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Merchantproduct } from "../../Shared/Merchantproduct";

export const Merchandisection = ({ merchandises, handleSort }) => {
  return (
    <div className="product_bar">
      <Container>
        <Row>
          <Col>
            <div className="pop_read">
              <h5>New Merchandise</h5>
              <Form.Select
                aria-label="Default select example"
                onChange={handleSort}
              >
                <option value={""}>Sort</option>
                <option value="2">Price High</option>
                <option value="1">Price Low</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <Row>
          {merchandises &&
            merchandises.length > 0 &&
            merchandises.map((merchandise) => (
              <Col lg={4} key={merchandise.id}>
                <Merchantproduct merchandise={merchandise} />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};
