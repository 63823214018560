import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Merchantproduct } from "../../Shared/Merchantproduct";

export const Merchandise = ({ merchandises }) => {
  return (
    <div className="popular_read">
      <Container>
        <Row>
          <Col>
            <div className="pop_read">
              <h5>Popular Products</h5>
              <Link to="/shop/merchandise">
                <span>See everything</span>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          {merchandises &&
            merchandises.length > 0 &&
            merchandises.map((merchandise) => (
              <Col lg={4} key={merchandise.id}>
                <Merchantproduct merchandise={merchandise} />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};
