import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { ProductDetails } from "../ProductDetails/ProductDetails";
import { Meet } from "./Meet";
import { connect } from "react-redux";
import { getReading } from "../../../store/actions/readingAction";
import { getMerchandise } from "../../../store/actions/merchandiseAction";
import {
  RESET_BLOG_DETAIL,
  RESET_MERCHANDISE_DETAIL,
} from "../../../store/common/types";
import { FormProduct } from "./FormProduct";
import { ReviewUser } from "../Reviews/ReviewUser";

const renderItem = (page, merchandise, reading) => {
  if (page === "reading") {
    return <ProductDetails detail={reading} />;
  } else if (page === "merchandise") {
    return <ProductDetails detail={merchandise} />;
  }
};

const ProductDetailLayout = ({
  getMerchandise,
  getReading,
  merchandise,
  reading,
  resetReading,
  resetMerchandise,
}) => {
  const { page, productId } = useParams();
  useEffect(() => {
    if (page === "reading") {
      getReading(productId);
    } else if (page === "merchandise") {
      getMerchandise(productId);
    }
    return () => {
      if (page === "reading") {
        resetReading();
      } else if (page === "merchandise") {
        resetMerchandise();
      }
    };
  }, [getReading, getMerchandise]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
      {renderItem(page, merchandise, reading)}
      <FormProduct page={page} merchandise={merchandise} reading={reading} />
      <Meet />

      <HomeFooter />
    </div>
  );
};
const mapStateToProps = ({
  reading: { detail: reading },
  merchandise: { detail: merchandise },
}) => {
  return {
    merchandise,
    reading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMerchandise: (payload) => dispatch(getMerchandise(payload)),
    getReading: (payload) => dispatch(getReading(payload)),
    resetReading: () => dispatch({ type: RESET_BLOG_DETAIL }),
    resetMerchandise: () => dispatch({ type: RESET_MERCHANDISE_DETAIL }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductDetailLayout);
