import React from 'react'
import image_url from '../../common/images'
export const About = () => {
  return (
    <div className='about'>
        <div className="about_box">
            <span><img src={image_url.abouts} alt="" /></span>
            <h5>Find New Friends </h5>
            <p>Expand your network by searching for people at the place you are currently in. </p>
        </div>
    </div>
  )
}
