// const baseurl = "http://localhost:3004/suntarot_web";
const baseurl = "https://new.emeraldsun-tarot.com";

const image_url = {
    logo: baseurl + "/assets/images/logo_home.svg",
    sinLogo: baseurl + "/assets/images/Logo.svg",
    herobanner: baseurl + "/assets/images/hero_img.svg",
    shopping: baseurl + "/assets/images/shopping.svg",
    merchant: baseurl + "/assets/images/merchandise.svg",
    banner: baseurl + "/assets/images/sideimg.png",
    product: baseurl + "/assets/images/shop_pro.svg",
    abouts: baseurl + "/assets/images/about_icon.svg",
    popularread: baseurl + "/assets/images/product_card.svg",
    google: baseurl + "/assets/images/GooglePlay.png",
    apple: baseurl + "/assets/images/AppStore.png",
    review: baseurl + "/assets/images/user_op.png",
    gridIcon: baseurl + "/assets/images/grid-sort.png",
    stripe_image: baseurl + "/assets/images/stripe.svg",
};
export default image_url;