import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_CART_ERROR,
  FETCH_CART_ITEM,
  FETCH_ORDER_DETAIL,
  FETCH_ORDER_DETAIL_ERROR,
  FORM_SUBMITTING,
  RESET_CART_ITEM,
  RESET_FORM_SUBMITTING,
  SET_CART_ITEM,
  SET_ORDER_DETAIL,
} from "../common/types";
import { toast } from "react-toastify";

export const addReadingToBag = (payload, resetForm) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.post(APIs.ADD_READING_TO_BAG, { ...payload });
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    if (data.status) {
      dispatch(
        getCartItemList({
          guest_userid: payload.guest_userid,
        })
      );
      resetForm();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    toast.error(err.message);
  }
};

export const addMerchandiseToBag = (payload, resetForm) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.post(APIs.ADD_MERCHANDISE_TO_BAG, {
      ...payload,
    });
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    if (data.status) {
      dispatch(
        getCartItemList({
          guest_userid: payload.guest_userid,
        })
      );
      resetForm();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    toast.error(err.message);
  }
};

export const getCartItemList = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_CART_ITEM });
    const { data } = await AXIOS.post(APIs.SHOPPING_CART_LIST, {
      ...payload,
    });
    if (data.status) {
      dispatch({
        type: SET_CART_ITEM,
        payload: data.data || [],
      });
    } else {
      dispatch({
        type: SET_CART_ITEM,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_CART_ERROR,
      payload: err,
    });
  }
};

export const deleteCartItem = async (cartId) => {
  try {
    const { data } = await AXIOS.get(`${APIs.REMOVE_CART_ITEM}/${cartId}`);
    if (data.status) {
      toast.success(data.message);
      return true;
    } else {
      toast.error(data.message);
      return false;
    }
  } catch (err) {
    toast.error(err.message);
  }
};

export const createPayment =
  (payload, resetForm, history) => async (dispatch) => {
    try {
      dispatch({
        type: FORM_SUBMITTING,
      });
      const { data } = await AXIOS.post(APIs.CREATE_PAYMENT, {
        ...payload,
      });
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      if (data.status) {
        dispatch({ type: RESET_CART_ITEM });
        // localStorage.removeItem("total_price");
        resetForm();
        // toast.success(data.message);
        // history.push("/payment-success");
        history.push("/order-confirm");
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      dispatch({
        type: RESET_FORM_SUBMITTING,
      });
      toast.error(err.message);
    }
  };

export const getOrderDetails = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_ORDER_DETAIL });
    const { data } = await AXIOS.post(APIs.GET_ORDER_DETAIL, {
      ...payload,
      // guest_userid: "F9E2F5FD-2FCA-411D-AED2-0177F8C064C1",
    });
    if (data.status) {
      dispatch({
        type: SET_ORDER_DETAIL,
        payload: data.data || {},
      });
    } else {
      dispatch({
        type: SET_ORDER_DETAIL,
        payload: data.data || {},
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_ORDER_DETAIL_ERROR,
      payload: err,
    });
  }
};
