import {
  FETCH_BLOG_ERROR,
  FETCH_BLOG_LIST,
  RESET_BLOG_DETAIL,
  RESET_BLOG_LIST,
  SET_BLOG_DETAIL,
  SET_BLOG_LIST,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  blogs: [],
  detail: {},
};

const blogReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_BLOG_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_BLOG_LIST:
      return { ...state, blogs: [...action.payload], loading: false };
    case RESET_BLOG_LIST:
      return {
        ...state,
        blogs: [],
      };
    case FETCH_BLOG_ERROR:
      return {
        ...state,
        blogs: [],
        loading: false,
        error: action.payload,
      };
    case SET_BLOG_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case RESET_BLOG_DETAIL:
      return {
        ...state,
        detail: {},
      };
    default:
      return state;
  }
};

export default blogReducer;
