import React, { useEffect } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { HomeHeader2 } from "../HomeHeader2";
import { getFaqs } from "../../../store/actions/guidelineAction";

const Faq = ({ getFaqs, faq_lists }) => {
  useEffect(() => {
    getFaqs();
  }, [getFaqs]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
      <HomeHeader2 />
      <Container>
        <Row>
          <Col>
            <div className="open_disclaimer">
              <div className="disclaimer faq">
                <div className="faq_box">
                  <h5>Frequently asked questions</h5>
                  <Accordion defaultActiveKey="0">
                    {faq_lists &&
                      faq_lists.length > 0 &&
                      faq_lists.map((faq, index) => (
                        <Accordion.Item
                          eventKey={`${index.toString()}`}
                          key={faq.id}
                          className="mt-2"
                        >
                          <Accordion.Header>{faq.header}</Accordion.Header>
                          <Accordion.Body>{faq.discription}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <HomeFooter />
    </div>
  );
};

const mapStateToProps = ({ guideline: { loading, faq_lists } }) => {
  return {
    faq_lists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getFaqs: (payload) => dispatch(getFaqs(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
