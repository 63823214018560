import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FORM_SUBMITTING,
  FORM_SUCCESS,
  RESET_FORM_SUBMITTING,
} from "../common/types";
import { toast } from "react-toastify";
export const sendContact = (payload, resetForm) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.post(APIs.ADD_CONTACT_US, { ...payload });
    dispatch({
      type: FORM_SUCCESS,
    });
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    if (data.status) {
      resetForm();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });

    toast.error(err.message);
  }
};

export const addRecheck = (payload, resetForm) => async (dispatch) => {
  try {
    dispatch({
      type: FORM_SUBMITTING,
    });
    const { data } = await AXIOS.post(APIs.ADD_RECHECK, { ...payload });
    dispatch({
      type: FORM_SUCCESS,
    });
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });
    if (data.status) {
      resetForm();
      toast.success(data.message);
    } else {
      toast.error(data.message);
    }
  } catch (err) {
    dispatch({
      type: RESET_FORM_SUBMITTING,
    });

    toast.error(err.message);
  }
};
