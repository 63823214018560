import React from "react";
import image_url from "../../common/images";
import { Container, Row, Col, Breadcrumb } from "react-bootstrap";
import { useHistory } from "react-router-dom";
export const ProductDetails = ({ detail }) => {
  const history = useHistory();
  return (
    <div className="product_bar blogbar meet_bg">
      <Container>
        <Row>
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item onClick={history.goBack}>Shop </Breadcrumb.Item>

              <Breadcrumb.Item active>{detail.title}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="left_box ">
              <h5>{detail.title}</h5>
              <span style={{ color: "#fff", fontSize: "16px" }}>
                £{detail.price}
              </span>
              <p>{detail.discription}</p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="product_photo">
              <img
                src={detail.image ? detail.image : image_url.product}
                alt={detail.title}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
