import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { About } from "./About";



export const ReviewSection = () => {
  return (
    <div className="product_bar blogbar">
      <Container>
      <Row>
          <Col>
            <div className="blog_row">
              <h5>About us</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa
                libero labore natus atque, ducimus sed.
              </p>
            
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
           <About/>
          </Col>
          <Col lg={4}>
          <About/>
          </Col>
          <Col lg={4}>
          <About/>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
          <About/>
          </Col>
          <Col lg={4}>
          <About/> 
          </Col>
          <Col lg={4}>
          <About/>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
