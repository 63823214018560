import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Icon } from "@iconify/react";
import image_url from "../../common/images";
export const ReviewUser = () => {
  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h5 className="user_title">User Reviews</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="user_review ">
              <div className="firdt_box">
                <div className="blog_op">
                  <div className="user_img">
                    <img src={image_url.review} alt="" />
                  </div>
                  <div className="user_name">
                    <name>Brenna Goyette</name>
                    <rating>
                      <Icon icon="ant-design:star-filled" />
                      <Icon icon="ant-design:star-filled" />
                      <Icon icon="ant-design:star" />
                      <Icon icon="ant-design:star" />
                      <Icon icon="ant-design:star" />
                    </rating>
                  </div>
                </div>
               
                <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
                <div className="img_review">
                    <img src={image_url.product} alt="" />
                    <img src={image_url.product} alt="" />
                    <img src={image_url.product} alt="" />
                    <img src={image_url.product} alt="" />
                </div>
              </div>
              <div className="firdt_box">
                <div className="blog_op">
                  <div className="user_img">
                    <img src={image_url.review} alt="" />
                  </div>
                  <div className="user_name">
                    <name>Brenna Goyette</name>
                    <rating>
                      <Icon icon="ant-design:star-filled" />
                      <Icon icon="ant-design:star-filled" />
                      <Icon icon="ant-design:star" />
                      <Icon icon="ant-design:star" />
                      <Icon icon="ant-design:star" />
                    </rating>
                  </div>
                </div>
               
                <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
                <div className="img_review">
                    <img src={image_url.product} alt="" />
                    <img src={image_url.product} alt="" />
                    <img src={image_url.product} alt="" />
                    <img src={image_url.product} alt="" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
