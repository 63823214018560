import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_MERCHANDISE_ERROR,
  FETCH_MERCHANDISE_LIST,
  SET_MERCHANDISE_DETAIL,
  SET_MERCHANDISE_LIST,
} from "../common/types";
import { toast } from "react-toastify";
export const getMerchandises = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MERCHANDISE_LIST });
    const { data } = await AXIOS.post(APIs.MERCHANDISE_LIST, { ...payload });

    if (data.status) {
      dispatch({
        type: SET_MERCHANDISE_LIST,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_MERCHANDISE_ERROR,
      payload: err,
    });
  }
};

export const getMerchandise = (merchandiseId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_MERCHANDISE_LIST });
    const { data } = await AXIOS.get(
      `${APIs.MERCHANDISE_DETAIL}/${merchandiseId}`
    );
    if (data.status) {
      dispatch({
        type: SET_MERCHANDISE_DETAIL,
        payload: data.data || {},
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_MERCHANDISE_ERROR,
      payload: err,
    });
  }
};
