import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import image_url from "../common/images";
import { HomeFooter } from "../LandingPages/HomeFooter";
import Homeheader from "../LandingPages/Homeheader";
import { Formik, ErrorMessage, Field } from "formik";
import { connect } from "react-redux";
import {
  createPayment,
  deleteCartItem,
  getCartItemList,
} from "../../store/actions/cartAction";
import { checkoutValidation } from "../common/validation";
import TextError from "../common/TextError";
import { Icon } from "@iconify/react";
import { useCookies } from "react-cookie";
import { REMOVE_CART_ITEM } from "../../store/common/types";

const CheckLayout = ({
  createPayment,
  submitting,
  getCartItemList,
  loading,
  cart_lists,
  removeItem,
}) => {
  const [cookies, setCookie] = useCookies(["user"]);
  const [total, setTotal] = useState(0);
  // const total = localStorage.getItem("total_price") || 0;
  const history = useHistory();
  const [readingIds, setReadingIds] = useState([]);
  const [merchandiseIds, setMerchandiseIds] = useState([]);

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    createPayment(
      {
        ...values,
        merchandise_id: merchandiseIds.toString(),
        reading_id: readingIds.toString(),
        guest_userid: cookies.guest_userid,
        amount: total,
      },
      resetForm,
      history
    );
    setSubmitting(false);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (cart_lists) {
      let data = [];
      let merchandise_ids = [];
      let reading_ids = [];
      cart_lists.forEach((cart) => {
        if (cart.Merchandis) {
          merchandise_ids.push(cart.Merchandis.id);
          data.push({
            id: cart.Merchandis.id,
            price: cart.Merchandis.price,
          });
        } else if (cart.Reading) {
          reading_ids.push(cart.Reading.id);
          data.push({
            id: cart.Reading.id,
            price: cart.Reading.price,
          });
        }
      });
      setReadingIds(reading_ids);
      setMerchandiseIds(merchandise_ids);
      const sum = data.reduce((total, item) => total + Number(item.price), 0);
      setTotal(sum);
    }
  }, [cart_lists]);

  useEffect(() => {
    getCartItemList({
      guest_userid: cookies.guest_userid,
    });
  }, [getCartItemList]);

  const removeCartItem = (cartId) => {
    deleteCartItem(cartId).then((res) => {
      if (res) {
        if (cart_lists.length === 1) {
          history.push("/");
        }
        removeItem(cartId);
      }
    });
  };

  return (
    <div>
      <Homeheader />
      <div className="checkout">
        <Container>
          <Row>
            <Col>
              {" "}
              <img className="mb-5" src={image_url.logo} alt="" />
            </Col>
          </Row>
          <Row>
            <Col> </Col>
          </Row>

          <Formik
            enableReinitialize={true}
            initialValues={{
              full_name: "",
              email: "",
              phone_no: "",
              name_on_card: "",
              card_no: "",
              expiryDate: "",
              cvc: "",
              address: "",
              city: "",
              state: "",
              postal: "",
              shipping: false,
              savelater: "1",
              amount: total,
            }}
            validationSchema={checkoutValidation}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="checkout__control">
                  <Col lg={5}>
                    <Row>
                      <Col>
                        <div className="mb-5 stripe__image">
                          <img src={image_url.stripe_image} alt="" />
                        </div>{" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Full Name <span style={{ color: "red" }}>*</span>
                          </Form.Label>

                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="full_name"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="full_name"
                            component={TextError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Email <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="email"
                            placeholder=""
                            className="form-control"
                            name="email"
                            autoComplete="off"
                          />
                          <ErrorMessage name="email" component={TextError} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Phone Number <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="phone_no"
                            autoComplete="off"
                          />
                          <ErrorMessage name="phone_no" component={TextError} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Name on card <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="name_on_card"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="name_on_card"
                            component={TextError}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Card Number <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="card_no"
                            autoComplete="off"
                          />
                          <ErrorMessage name="card_no" component={TextError} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Expiration date (MM/YY)
                            <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="date"
                            placeholder=""
                            className="form-control"
                            name="expiryDate"
                            autoComplete="off"
                          />
                          <ErrorMessage
                            name="expiryDate"
                            component={TextError}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            CVC <span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="cvc"
                            autoComplete="off"
                          />
                          <ErrorMessage name="cvc" component={TextError} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>
                            Address<span style={{ color: "red" }}>*</span>
                          </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="address"
                            autoComplete="off"
                          />
                          <ErrorMessage name="address" component={TextError} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>City</Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="city"
                            autoComplete="off"
                          />
                          <ErrorMessage name="city" component={TextError} />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>State / Province </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="state"
                            autoComplete="off"
                          />
                          <ErrorMessage name="state" component={TextError} />
                        </Form.Group>
                      </Col>
                      <Col lg={4}>
                        {" "}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Postal code </Form.Label>
                          <Field
                            type="text"
                            placeholder=""
                            className="form-control"
                            name="postal"
                            autoComplete="off"
                          />
                          <ErrorMessage name="postal" component={TextError} />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          {/* <Form.Check
                            aria-label="yes"
                            label="Billing address is the same as shipping address"
                          /> */}
                          <Field name="shipping">
                            {({ field }) => (
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  {...field}
                                  checked={field.value}
                                  className="form-check-input"
                                />
                                <label className="form-check-label">
                                  Billing address is the same as shipping
                                  address
                                </label>
                              </div>
                            )}
                          </Field>
                        </Form.Group>
                      </Col>
                    </Row>
                    <button
                      type="submit"
                      className="d-block w-100 mb-5 product_btn"
                      disabled={submitting}
                    >
                      {submitting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        <span> Pay £{total}</span>
                      )}
                    </button>
                  </Col>
                  <Col lg={2}></Col>
                  <Col lg={5}>
                    <div className="shopping_bag_checkout">
                      {cart_lists &&
                        cart_lists.length > 0 &&
                        cart_lists.map((cart_list) => (
                          <div
                            className="flex_check custom_margin"
                            key={cart_list.id}
                          >
                            <div className="pup_up">
                              {cart_list.Merchandis && (
                                <img
                                  src={
                                    cart_list.Merchandis.image
                                      ? cart_list.Merchandis.image
                                      : image_url.product
                                  }
                                  alt=""
                                />
                              )}
                              {cart_list.Reading && (
                                <img
                                  src={
                                    cart_list.Reading.image
                                      ? cart_list.Reading.image
                                      : image_url.product
                                  }
                                  alt=""
                                />
                              )}
                            </div>
                            <div className="pupup_details">
                              {cart_list.Merchandis && (
                                <>
                                  <h5>{cart_list.Merchandis.title}</h5>
                                  <p>£{cart_list.Merchandis.price}</p>
                                </>
                              )}
                              {cart_list.Reading && (
                                <>
                                  <h5>{cart_list.Reading.title}</h5>
                                  <p>£{cart_list.Reading.price}</p>
                                </>
                              )}
                              <div className="time_box">
                                <Icon icon="uil:calender" color="#6b7280" />
                                <span className="timebox">
                                  <span>{cart_list.date}</span>
                                </span>
                              </div>
                              <div className="op_cart">
                                {/* {cart_list.Merchandis && (
                                  <Link
                                    to={`/shop/product/merchandise/${cart_list.Merchandis.id}`}
                                  >
                                    {" "}
                                    <span className="edit__text">
                                      Edit
                                    </span>{" "}
                                  </Link>
                                )} */}
                                {/* {cart_list.Reading && (
                                  <Link
                                    to={`/shop/product/reading/${cart_list.Reading.id}`}
                                  >
                                    {" "}
                                    <span className="edit__text">
                                      Edit
                                    </span>{" "}
                                  </Link>
                                )}
                                |{" "} */}
                                <span
                                  onClick={() => removeCartItem(cart_list.id)}
                                >
                                  Remove
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}

                      <div className="sub_total_bag">
                        <p className="sub">
                          <span>Subtotal</span>
                          <span>£{total}</span>
                        </p>

                        <p className="one_end">
                          <span>Order total</span>
                          <span>£{total}</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </div>
      <HomeFooter />
    </div>
  );
};

const mapStateToProps = ({ form, cart: { loading, cart_lists } }) => {
  return {
    submitting: form.submitting,
    loading,
    cart_lists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPayment: (payload, resetForm, history) =>
      dispatch(createPayment(payload, resetForm, history)),
    getCartItemList: (payload) => dispatch(getCartItemList(payload)),
    removeItem: (cartId) =>
      dispatch({ type: REMOVE_CART_ITEM, payload: { cartId } }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckLayout);
