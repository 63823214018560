import React ,{useEffect}from "react";
import { Container, Row, Col, Form, Spinner } from "react-bootstrap";
import { addRecheck } from "../../../store/actions/authAction";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { Formik, ErrorMessage, Field } from "formik";
import TextError from "../../common/TextError";
import { connect } from "react-redux";
import { HomeHeader2 } from "../HomeHeader2";
import { rechcekValidation } from "../../common/validation";

const Rechecks = ({ addRecheck, submitting }) => {
  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    addRecheck(values, resetForm);
    setSubmitting(false);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
    
      <Container>
        <Row>
          <Col>
            <div className="open_disclaimer">
              <div className="disclaimer ">
                <div className="faq_box">
                  <h5>FERTILITY RECHECKS</h5>
                  <p>
                    Rechecks apply for fertility readings only. At present;
                    rechecks are free of charge ONLY if your previous prediction
                    passed with no success.
                  </p>
                  <p>
                    If your current cycle began in the month predicted, you
                    would not be eligible for a recheck until a new cycle begins
                    outwith the predicted month. If you do not have regular
                    cycles due to a condition such as PCOS, you will be entitled
                    to a recheck 4 weeks after the predicted month has passed
                    (28th of the following month).
                  </p>
                  <p>
                    If you do not meet this criteria, you will not be authorised
                    for a free recheck until such a time that you are eligible.
                    We have had to get strict with this criteria due to many
                    people requesting and receiving a recheck, only for the
                    original prediction to be accurate.
                  </p>
                  <p>
                    Rechecks include the following information: conception
                    month, gender, signs, and numbers. Rechecks are to the point
                    with one word answers. This is a standardised recheck - you
                    will not receive the same quantity of information and the
                    same level of description as your original reading.
                  </p>
                  <p>
                    We am transparent in the fact that we cannot guarantee
                    outcomes for readings. We have a high accuracy rate which
                    appears to be around 80% based on feedback. As our
                    popularity begins to grow, this may result in an increase in
                    rechecks. At present, we don’t get many requests for these.
                    Currently, our rechecks are a courtesy service and we am not
                    bound to these: therefore we do reserve the right to charge
                    a small administration fee in the future if recheck requests
                    do rise.
                  </p>
                  <p>
                    Delivery: Under normal circumstances, we strive to deliver
                    rechecks within 14 days. At times of high demand, free
                    rechecks may take up to 28 days for delivery. If you require
                    quicker delivery than this, please advise on the contact
                    form. Quicker delivery will incur a small administration
                    charge and will fall within the 5 business days turnaround.
                  </p>
                </div>
                <Formik
                  initialValues={{
                    your_name: "",
                    your_email: "",
                    previous_order_number: "",
                    previous_month_predictions: "",
                    last_date: "",
                    dob: "",
                    completed_your_original_reading: "",
                    qualifying_criteria: false,
                    understood: false,
                    My_prediction: false,
                    Other_information: "",
                  }}
                  validationSchema={rechcekValidation}
                  onSubmit={handleSubmit}
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit} className="mt-5">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>Your Name</Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              type="text"
                              placeholder="Your name"
                              className="form-control"
                              name="your_name"
                            />
                            <ErrorMessage
                              name="your_name"
                              component={TextError}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>Your Email</Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              type="email"
                              placeholder="Your email"
                              className="form-control"
                              name="your_email"
                            />
                            <ErrorMessage
                              name="your_email"
                              component={TextError}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>Date of Birth</Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              type="date"
                              placeholder=""
                              className="form-control"
                              name="dob"
                            />
                            <ErrorMessage name="dob" component={TextError} />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>Previous Order Number</Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              type="text"
                              placeholder=""
                              className="form-control"
                              name="previous_order_number"
                            />
                            <ErrorMessage
                              name="previous_order_number"
                              component={TextError}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>Previous month predictions</Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              type="text"
                              placeholder=""
                              className="form-control"
                              name="previous_month_predictions"
                            />
                            <ErrorMessage
                              name="previous_month_predictions"
                              component={TextError}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>
                              date last cycle/period began
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              type="date"
                              placeholder=""
                              className="form-control"
                              name="last_date"
                            />
                            <ErrorMessage
                              name="last_date"
                              component={TextError}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>
                              who completed your original reading?
                            </Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              as="select"
                              name="completed_your_original_reading"
                              className="form-control select_box"
                            >
                              <option value={""}>select a option</option>
                              <option value="Gemma">Gemma</option>
                              <option value="Michelle">Michelle</option>
                            </Field>
                            <ErrorMessage
                              name="completed_your_original_reading"
                              component={TextError}
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={12}>
                            <Form.Label>
                              i have read the qualifying criteria for a recheck
                            </Form.Label>

                            <Field name="qualifying_criteria">
                              {({ field }) => (
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    {...field}
                                    checked={field.value}
                                    className="form-check-input"
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={12}>
                            <Form.Label>
                              i have understood that i am entitled to only 1
                              free recheck
                            </Form.Label>
                            <Field name="understood">
                              {({ field }) => (
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    {...field}
                                    checked={field.value}
                                    className="form-check-input"
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={12}>
                            <Form.Label>
                              My prediction has fully passed and I have begun a
                              new cycle in the month after my prediction (or I
                              have irregular periods and more than 28 days have
                              passed since my predicted month ended)
                            </Form.Label>
                            <Field name="My_prediction">
                              {({ field }) => (
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    {...field}
                                    checked={field.value}
                                    className="form-check-input"
                                  />
                                  <label className="form-check-label">
                                    Yes
                                  </label>
                                </div>
                              )}
                            </Field>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={12}>
                            <Form.Label>
                              If you do not meet these terms, please re-apply
                              for a recheck once you do.
                            </Form.Label>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Row>
                          <Col lg={3}>
                            {" "}
                            <Form.Label>Other Information</Form.Label>
                          </Col>
                          <Col lg={9}>
                            {" "}
                            <Field
                              as="textarea"
                              rows={6}
                              placeholder=""
                              className="form-control"
                              name="Other_information"
                            />
                          </Col>
                        </Row>
                      </Form.Group>
                      <button
                        type="submit"
                        className="product_btn d-block pt-4 pb-4"
                        disabled={submitting}
                      >
                        {submitting ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          <span> Submit</span>
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <HomeFooter />
    </div>
  );
};
const mapStateToProps = ({ form }) => {
  return {
    submitting: form.submitting,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addRecheck: (payload, resetForm) =>
      dispatch(addRecheck(payload, resetForm)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Rechecks);
