import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import HomepageLayout from "../Components/LandingPages/Homepage/HomepageLayout";
import ProductpageLayout from "../Components/LandingPages/ProductPage/ProductpageLayout";
import { Disclaimer } from "../Components/LandingPages/Disclaimer/Disclaimer";
import { TermsCondition } from "../Components/LandingPages/Terms/TermsCondition";
import Faq from "../Components/LandingPages/FAQ/Faq";
import Login from "../auth/Login";
import Register from "../auth/Register";
import BlogLayout from "../Components/LandingPages/Blog/BlogLayout";
import ShoppingCart from "../Components/LandingPages/Shoppingcart/ShoppingCart";
import MerchantpageLayout from "../Components/LandingPages/Merchandise/MerchantpageLayout";
import BlogDetails from "../Components/LandingPages/Blog/BlogDetails";
import { ShippingPolicy } from "../Components/LandingPages/ShippingPolicy/ShippingPolicy";
import { ReturnPolicy } from "../Components/LandingPages/ReturnPolicy/ReturnPolicy";
import { ReviewLayout } from "../Components/LandingPages/Reviews/ReviewLayout";
import ProductDetailLayout from "../Components/LandingPages/ProductDetails/ProductDetailLayout";
import CheckLayout from "../Components/checkout/CheckLayout";
import { Aboutus } from "../Components/LandingPages/Aboutus/Aboutus";
import Contactus from "../Components/Contactus/Contactus";
import Rechecks from "../Components/LandingPages/FertilityChecks/Rechecks";
import DateandSelect from "../Components/LandingPages/FertilityChecks/DateandSelect";
import PaymentSuccess from "../Components/checkout/PaymentSuccess";
import Orderpage from "../Components/checkout/Orderpage";
export const Router = () => {
  return (
    <div>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" exact component={HomepageLayout} />
          <Route path="/shop/reading" exact component={ProductpageLayout} />
          <Route
            path="/shop/merchandise"
            exact
            component={MerchantpageLayout}
          />
          <Route path="/blog" exact component={BlogLayout} />
          <Route path="/review" exact component={ReviewLayout} />
          <Route
            path="/shop/product/:page/:productId"
            exact
            component={ProductDetailLayout}
          />
          <Route path="/shoppingcart" exact component={ShoppingCart} />
          <Route path="/termsandconditions" exact component={TermsCondition} />
          <Route path="/faq" exact component={Faq} />
          <Route path="/disclaimer" exact component={Disclaimer} />
          <Route path="/shippingpolicy" exact component={ShippingPolicy} />
          <Route path="/returnpolicy" exact component={ReturnPolicy} />
          <Route path="/login" exact component={Login} />
          <Route path="/register" exact component={Register} />
          <Route
            path="/blog/blog-detail/:blogId"
            exact
            component={BlogDetails}
          />
          <Route path="/shoppingcart/checkout" component={CheckLayout} />
          <Route path="/aboutus" component={Aboutus} />
          <Route path="/contactus" component={Contactus} />
          <Route path="/rechecks" component={Rechecks} />
          <Route path="/selectdate" component={DateandSelect} />
          <Route path="/payment-success" component={PaymentSuccess} />
          <Route path="/order-confirm" component={Orderpage} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};
