import { Router } from "./Routes/Router";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
const COOKIE_EXPIRED = 60 * 60 * 24 * 365;

function App() {
  const [cookies, setCookie] = useCookies(["user"]);
  useEffect(() => {
    if (!cookies.guest_userid) {
      setCookie("guest_userid", uuidv4().toUpperCase(), {
        path: "/",
        maxAge: COOKIE_EXPIRED,
      });
    }
  }, [cookies, setCookie]);
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
