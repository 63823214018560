import React, { useState } from "react";
import { Form, FormControl } from "react-bootstrap";

import { Link } from "react-router-dom";

import "./auth.css";

import image_url from "../Components/common/images";
import { MailverifyLink } from "../modals/MailverifyLink";
const Register = () => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="login_panel">
        <div className="login_form">
          <div className="form_box">
            <Form className="mb-3">
              <div className="logo_box">
                <img className="log_img" src={image_url.sinLogo} alt="" />
              </div>
              <div className="title_box">
                <h5>Sign up to get started</h5>
                <span>Or</span>
                <Link className="pl-2" to="/login">
                  Sign in to your account
                </Link>
              </div>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>First Name</Form.Label>
                <FormControl type="text" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Last Name</Form.Label>
                <FormControl type="text" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <FormControl type="email" />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <FormControl type="password" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Confirm Password</Form.Label>
                <FormControl type="password" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Date of Birth</Form.Label>
                <FormControl type="date" placeholder="" />
              </Form.Group>
              <div className="dplay mb-4">
                <Form.Group className="" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember me" />
                </Form.Group>
              </div>
            </Form>
            <button
              onClick={() => setShow(true)}
              className="sign_btn d-block w-100"
            >
              Create Account
            </button>
          </div>
          <MailverifyLink show={show} onHide={() => setShow(false)} />
        </div>
        <div className="side_img">
          <img src={image_url.banner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Register;
