import React, { useEffect } from "react";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { HomeHeader2 } from "../HomeHeader2";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAboutUs } from "../../../store/actions/guidelineAction";
export const Aboutus = () => {
  const { about_us } = useSelector((state) => state.guideline);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAboutUs());
  }, [dispatch]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
      <HomeHeader2 />
      <Container>
        <Row>
          <Col>
            <div className="open_disclaimer">
              <div className="disclaimer">
                <h5>About Us</h5>
                <p className="formatted__description">{about_us.about_us}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <HomeFooter />
    </div>
  );
};
