const NAVIGATION = {
  BLOG_LIST: "/blog",
  BLOG_DETAIL: "/blog/blog-detail",

  MERCHANDISE_LIST: "/merchandise",
  MERCHANDISE_DETAIL: "/MERCHANDISE/blog-detail",

  RAEDING_LIST: "/shop/reading",
  PRODUCT_DETAIL: "/shop/product",
};
export { NAVIGATION };
