import React, { useEffect } from "react";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { ProductSection } from "./ProductSection";
import "../landingpage.css";
import { connect } from "react-redux";
import { getReadings } from "../../../store/actions/readingAction";

const ProductpageLayout = ({ loading, readings, getReadings }) => {
  useEffect(() => {
    getReadings({});
  }, [getReadings]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  const handleSort = (val) => {
    getReadings({ sort: val.target.value });
  };
  return (
    <div>
      <Homeheader />
      <ProductSection readings={readings || []} handleSort={handleSort} />
      <HomeFooter />
    </div>
  );
};
const mapStateToProps = ({ reading: { loading, readings } }) => {
  return {
    loading,
    readings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReadings: (payload) => dispatch(getReadings(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductpageLayout);
