import React, { useEffect } from "react";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { Icon } from "@iconify/react";
import { Container, Row, Col } from "react-bootstrap";
import image_url from "../../common/images";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBlog } from "../../../store/actions/blogAction";
import { dateToFormat } from "../../common/_helper";
import parse from "html-react-parser";
import { RESET_BLOG_DETAIL } from "../../../store/common/types";

const BlogDetails = () => {
  const { blogId } = useParams();
  const { loading, detail } = useSelector((state) => state.blog);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBlog(blogId));
    return () => dispatch({ type: RESET_BLOG_DETAIL });
  }, [blogId, dispatch]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
      <div className="blog_details_box">
        <Container>
          <Row>
            <Col>
              <h5 className="header_blog">{detail.header}</h5>
              <div className="blog_op">
                {/* <div className="user_img">
                  <img src="" alt="" />
                </div> */}
                <div className="user_name">
                  <span>Brenna Goyette</span>{" "}
                  <span>
                    {detail.publish_date &&
                      dateToFormat(detail.publish_date, "MMM DD, YYYY")}
                    · 4 min read
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="content_img">
                <img
                  src={detail.image ? detail.image : image_url.banner}
                  alt={detail.header}
                />
              </div>
              <div className="blog_content">{detail.discription || ""}</div>
              <div className="blog_content">
                {parse(detail.blog_content || "")}
              </div>
              <div className="link_box text-center">
                {/* <button><Icon icon="bi:arrow-left" color="#ab8d60" /><span>View all </span></button> */}
                <Link to="/blog">
                  <Icon icon="bi:arrow-left" color="#ab8d60" />
                  <span>View all posts</span>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <HomeFooter />
    </div>
  );
};

export default BlogDetails;
