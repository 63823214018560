import React from "react";
import { Form, FormControl } from "react-bootstrap";

import { Link } from "react-router-dom";

import "./auth.css";

import image_url from "../Components/common/images";
const Login = () => {
  return (
    <div>
      <div className="login_panel">
        <div className="login_form">
          <Form>
            <div className="logo_box">
              <img className="log_img" src={image_url.sinLogo} alt="" />
            </div>
            <div className="title_box">
              <h5>Sign in to your account</h5>
              <span>Or</span>
              <Link className="pl-2" to="/register">
                Create a new account
              </Link>
            </div>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <FormControl type="text" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <FormControl type="password" />
            </Form.Group>
            <div className="dplay mb-4">
              <Form.Group className="" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" label="Remember me" />
              </Form.Group>

              <Link to="/" className="forgot_pass">
                Forgot Password?
              </Link>
            </div>
            <button type="submit" className="sign_btn d-block w-100">
              Log in
            </button>
          </Form>
        </div>
        <div className="side_img">
          <img src={image_url.banner} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Login;
