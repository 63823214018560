import React,{useEffect} from "react";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import { ReviewSection } from "./ReviewSection";
import { HeroSection } from "../Homepage/HeroSection";
import { BannerHome } from "../Homepage/BannerHome";
import "../landingpage.css";
import { ReviewUser } from "./ReviewUser";

export const ReviewLayout = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div>
      <Homeheader />
      <HeroSection />
      <ReviewSection />
      <div className="ban_box" style={{ marginBottom: "60px" }}>
       
      </div>
      <ReviewUser />
      <HomeFooter />
    </div>
  );
};
