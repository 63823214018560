import {
  FETCH_POPURLAR_ERROR,
  FETCH_POPURLAR_LIST,
  RESET_POPURLAR_LIST,
  SET_POPURLAR_LIST,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  readings: [],
  merchandises: [],
  blogs: [],
};

const popularReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POPURLAR_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_POPURLAR_LIST:
      return {
        ...state,
        readings: action.payload.readings,
        merchandises: action.payload.merchandises,
        blogs: action.payload.blogs,
        loading: false,
      };
    case RESET_POPURLAR_LIST:
      return {
        ...state,
        readings: [],
        merchandises: [],
        blogs: [],
      };
    case FETCH_POPURLAR_ERROR:
      return {
        ...state,
        readings: [],
        merchandises: [],
        blogs: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default popularReducer;
