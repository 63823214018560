import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_GUIDELINE_ERROR,
  FETCH_GUIDELINE_LIST,
  SET_ABOUT_US,
  SET_DISCLAIMER,
  SET_FAQ_LIST,
  SET_RETURN_POLICY,
  SET_SHIPPING_POLICY,
  SET_TERM_CONDITION,
} from "../common/types";
import { toast } from "react-toastify";

export const getFaqs = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GUIDELINE_LIST });
    const { data } = await AXIOS.post(APIs.FAQ_LIST, { ...payload });

    if (data.status) {
      dispatch({
        type: SET_FAQ_LIST,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GUIDELINE_ERROR,
      payload: err,
    });
  }
};

export const getDisclaimer = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GUIDELINE_LIST });
    const { data } = await AXIOS.get(APIs.DISCLAIMER);

    if (data.status) {
      dispatch({
        type: SET_DISCLAIMER,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GUIDELINE_ERROR,
      payload: err,
    });
  }
};

export const getToc = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GUIDELINE_LIST });
    const { data } = await AXIOS.get(APIs.TERMS_CONDITION);

    if (data.status) {
      dispatch({
        type: SET_TERM_CONDITION,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GUIDELINE_ERROR,
      payload: err,
    });
  }
};

export const getReturnPolicy = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GUIDELINE_LIST });
    const { data } = await AXIOS.get(APIs.RETURN_POLICY);

    if (data.status) {
      dispatch({
        type: SET_RETURN_POLICY,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GUIDELINE_ERROR,
      payload: err,
    });
  }
};

export const getShippingPolicy = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GUIDELINE_LIST });
    const { data } = await AXIOS.get(APIs.SHIPPING_POLICY);

    if (data.status) {
      dispatch({
        type: SET_SHIPPING_POLICY,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GUIDELINE_ERROR,
      payload: err,
    });
  }
};

export const getAboutUs = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_GUIDELINE_LIST });
    const { data } = await AXIOS.get(APIs.GET_ABOUT_US);

    if (data.status) {
      dispatch({
        type: SET_ABOUT_US,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_GUIDELINE_ERROR,
      payload: err,
    });
  }
};
