import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_READING_ERROR,
  FETCH_READING_LIST,
  SET_READING_DETAIL,
  SET_READING_LIST,
} from "../common/types";
import { toast } from "react-toastify";
export const getReadings = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_READING_LIST });
    const { data } = await AXIOS.post(APIs.READING_LIST, { ...payload });

    if (data.status) {
      dispatch({
        type: SET_READING_LIST,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_READING_ERROR,
      payload: err,
    });
  }
};

export const getReading = (readingId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_READING_LIST });
    const { data } = await AXIOS.get(`${APIs.READING_DETAIL}/${readingId}`);
    if (data.status) {
      dispatch({
        type: SET_READING_DETAIL,
        payload: data.data || {},
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_READING_ERROR,
      payload: err,
    });
  }
};
