import {
  FETCH_CART_ERROR,
  FETCH_CART_ITEM,
  FETCH_ORDER_DETAIL,
  FETCH_ORDER_DETAIL_ERROR,
  FORM_SUBMITTING,
  FORM_SUCCESS,
  INITIALIZE_FORM,
  REMOVE_CART_ITEM,
  RESET_CART_ITEM,
  RESET_FORM_SUBMITTING,
  RESET_ORDER_DETAIL,
  SET_CART_ITEM,
  SET_ORDER_DETAIL,
} from "../common/types";

const formState = {
  success: false,
  submitting: false,
};
export const formReducer = (state = formState, action) => {
  switch (action.type) {
    case INITIALIZE_FORM:
      return {
        ...state,
        success: false,
      };
    case FORM_SUCCESS:
      return {
        ...state,
        success: true,
      };
    case FORM_SUBMITTING:
      return {
        ...state,
        submitting: true,
      };
    case RESET_FORM_SUBMITTING:
      return {
        ...state,
        submitting: false,
      };
    default:
      return state;
  }
};

const initialState = {
  error: "",
  loading: false,
  cart_lists: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CART_ITEM:
      return {
        ...state,
        loading: true,
      };
    case SET_CART_ITEM:
      return { ...state, cart_lists: [...action.payload], loading: false };
    case REMOVE_CART_ITEM:
      return {
        ...state,
        cart_lists: [
          ...state.cart_lists.filter(
            (cart) => cart.id !== action.payload.cartId
          ),
        ],
      };
    case FETCH_CART_ERROR:
      return {
        ...state,
        cart_lists: [],
        error: action.payload,
        loading: false,
      };
    case RESET_CART_ITEM:
      return {
        ...state,
        cart_lists: [],
        loading: false,
      };

    default:
      return state;
  }
};

const initialState1 = {
  error: "",
  loading: false,
  details: {},
};

export const orderReducer = (state = initialState1, action) => {
  switch (action.type) {
    case FETCH_ORDER_DETAIL:
      return {
        ...state,
        loading: true,
      };
    case SET_ORDER_DETAIL:
      return { ...state, details: { ...action.payload }, loading: false };

    case FETCH_ORDER_DETAIL_ERROR:
      return {
        ...state,
        details: {},
        error: action.payload,
        loading: false,
      };
    case RESET_ORDER_DETAIL:
      return { ...state, details: {}, loading: false };

    default:
      return state;
  }
};
export default cartReducer;
