import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';

export const HomeHeader2 = () => {
  return (
    <div className=''>
        <div className="offer_build">
                <Container>
                    <Row>
                        <Col lg={4}>
                           <div className="tarot_web">
                           <div className="exclusive_box">
                                <span>Download the app</span>
                                <h5>Get an exclusive $5 off code</h5>
                            </div>
                           </div>
                        </Col>
                        <Col lg={4}>
                        <div className="tarot_web box_3">
                           <div className="exclusive_box ">
                                <span>Return when you’re ready</span>
                                <h5>60 days of free return</h5>
                            </div>
                           </div>
                        </Col>
                        <Col lg={4}>
                        <div className="tarot_web">
                           <div className="exclusive_box">
                                <span>Sign uo for our newsletter</span>
                                <h5>15% off your first order</h5>
                            </div>
                           </div>
                        </Col>
                    </Row>
                </Container>
            </div>
    </div>
  )
}
