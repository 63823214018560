import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image_url from "../common/images";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
export const HomeFooter = () => {
  return (
    <div className="footer">
      <Container>
        <Row>
          <Col>
            <div className="logo_box">
              <img
                src={image_url.logo}
                alt=""
              />
            </div>
          </Col>
        </Row>
        <Row>
          
          <Col lg={3}>
            <div className="quik_links">
              <h5>Legal</h5>
              <ul>
                <li><Link to="/disclaimer">Disclaimer</Link></li>
                <li><Link to="/faq">FAQ</Link></li>
                <li><Link to="/returnpolicy">Return Policy </Link></li>
                <li><Link to="/shippingpolicy">Shipping Policy </Link></li>
                <li><Link to="/termsandconditions">Terms & Conditions</Link></li>
              </ul>
            </div>
          </Col>
          <Col lg={3}>
            <div className="quik_links">
              <h5>Company</h5>
              <ul>
                <li><Link to="/aboutus">About us</Link></li>
              </ul>
              <div className="social_link">
                <a href=""><Icon icon="ant-design:instagram-filled" /></a>
                <a href=""><Icon icon="akar-icons:twitter-fill" /></a>
                <a href=""><Icon icon="ant-design:instagram-filled" /></a>
                <a href=""><Icon icon="akar-icons:linkedin-fill" /></a>
              </div>
            </div>
          </Col>
        </Row>
        <Row><Col>

        </Col></Row>
      </Container>
      <div className="foot_bottom">
        <Container fluid>
          <Row>
            <Col>  <div className="anydesk_open">
              <p>© Copyright Emeraldsun Tarot</p>
              <div className="access_link">
                <a href=""><img src={image_url.apple} className="me-3" alt="" /></a>
                <a href=""><img src={image_url.google} alt="" /></a>
              </div>
            </div></Col>
          </Row>
        </Container>
      </div>
      </div>
      );
};
