import * as Yup from "yup";
export const contactValidation = Yup.object().shape({
  email: Yup.string()
    .email("please enter valid email address")
    .required("email field is required")
    .label("email"),
  name: Yup.string().required().label("name"),
  order_number: Yup.string().required().label("order number"),
  message: Yup.string().required().label("message"),
});

export const rechcekValidation = Yup.object().shape({
  your_name: Yup.string().required().label("name"),
  your_email: Yup.string()
    .email("please enter valid email address")
    .required("email field is required")
    .label("email"),
  previous_order_number: Yup.string().required().label("previous order number"),
  previous_month_predictions: Yup.string()
    .required()
    .label("previous month prediction"),
  dob: Yup.string().label("dob"),
  last_date: Yup.string().required().label("last date"),
  completed_your_original_reading: Yup.string().required().label("reading"),
  qualifying_criteria: Yup.boolean().label("qualifying criteria"),
  understood: Yup.boolean(),
  My_prediction: Yup.boolean(),
  Other_information: Yup.string(),
});

export const checkoutValidation = Yup.object().shape({
  full_name: Yup.string().required().label("full name"),
  email: Yup.string()
    .email("please enter valid email address")
    .required("email field is required")
    .label("email"),
  phone_no: Yup.string().required().label("phone"),
  address: Yup.string().required().label("address"),
  city: Yup.string().required().label("city"),
  state: Yup.string().required().label("state"),
  postal: Yup.string().required().label("postal"),

  name_on_card: Yup.string().required().label("card name"),
  card_no: Yup.string().min(16).max(16).required().label("card no"),
  expiryDate: Yup.string().required().label("expiry date"),
  cvc: Yup.string().required().min(3).max(3).label("cvc"),
  shipping: Yup.boolean().label("shipping"),
});
