import React from "react";
import Modal from "react-bootstrap/Modal";
import { Icon } from "@iconify/react";
import "./modal.css"
export const MailverifyLink = (props) => {
  return (
    <div>
      <Modal {...props}>
        <Modal.Header>
          <span>
            <Icon icon="fluent:mail-20-filled" />
          </span>
          {/* closeButton={props.onHide} */}
        </Modal.Header>
        <Modal.Body>
          <p>Confirmation email has been sent.</p>
          <button className="sign_btn d-block w-100" onClick={props.onHide}>
            {" "}
            Check Email
          </button>
          {/* <Link to="/reset-password" className="sign_btn d-block">Check Email</Link> */}
        </Modal.Body>
      </Modal>
    </div>
  );
};
