import {
  FETCH_READING_ERROR,
  FETCH_READING_LIST,
  RESET_READING_DETAIL,
  RESET_READING_LIST,
  SET_READING_DETAIL,
  SET_READING_LIST,
} from "../common/types";

const initialState = {
  error: "",
  loading: false,
  readings: [],
  detail: {},
};

const readingReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_READING_LIST:
      return {
        ...state,
        loading: true,
      };
    case SET_READING_LIST:
      return { ...state, readings: [...action.payload], loading: false };
    case RESET_READING_LIST:
      return {
        ...state,
        readings: [],
      };
    case FETCH_READING_ERROR:
      return {
        ...state,
        readings: [],
        loading: false,
        error: action.payload,
      };
    case SET_READING_DETAIL:
      return {
        ...state,
        detail: action.payload,
      };
    case RESET_READING_DETAIL:
      return {
        ...state,
        detail: {},
      };
    default:
      return state;
  }
};

export default readingReducer;
