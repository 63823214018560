import AXIOS from "../common/api_helper";
import { APIs } from "../common/constants";
import {
  FETCH_BLOG_ERROR,
  FETCH_BLOG_LIST,
  SET_BLOG_DETAIL,
  SET_BLOG_LIST,
} from "../common/types";
import { toast } from "react-toastify";
export const getBlogs = (payload) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BLOG_LIST });
    const { data } = await AXIOS.post(APIs.BLOG_LIST, { ...payload });

    if (data.status) {
      dispatch({
        type: SET_BLOG_LIST,
        payload: data.data || [],
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_BLOG_ERROR,
      payload: err,
    });
  }
};

export const getBlog = (blogId) => async (dispatch) => {
  try {
    dispatch({ type: FETCH_BLOG_LIST });
    const { data } = await AXIOS.get(`${APIs.BLOG_DETAIL}/${blogId}`);
    if (data.status) {
      dispatch({
        type: SET_BLOG_DETAIL,
        payload: data.data || {},
      });
    }
  } catch (err) {
    toast.error(err.message);
    dispatch({
      type: FETCH_BLOG_ERROR,
      payload: err,
    });
  }
};
