import React from "react";
import "./common.css";
import { Link } from "react-router-dom";
import { NAVIGATION } from "../common/constants";
import image_url from "../common/images";

export const ProductCard = ({ reading }) => {
  return (
    <div className=" op_main">
      <div className="product_card">
        <img src={image_url.popularread} alt={reading.title} />
        <h5 className="text_overflow"> {reading.title}</h5>
        <h4> £{reading.price}</h4>
        {/* <button className='product_btn'>View Product</button> */}
        <Link to={`${NAVIGATION.PRODUCT_DETAIL}/reading/${reading.id}`}>
          <button className="product_btn">View Product</button>
        </Link>
      </div>
    </div>
  );
};
