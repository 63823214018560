import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BlogBox } from "../../Shared/BlogBox";
import { Link } from "react-router-dom";
export const Blogpanel = ({ blogs }) => {
  return (
    <div className="blog_panel">
      <Container>
        <Row>
          <Col>
            <div className="blog_row">
              <h5>From the blog</h5>
              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa
                libero labore natus atque, ducimus sed.
              </p>
              <Link to="/blog">
                <span>See all blogs</span>
              </Link>
            </div>
          </Col>
        </Row>
        <Row>
          {blogs &&
            blogs.length > 0 &&
            blogs.map((blog) => (
              <Col lg={4} key={blog.id}>
                <BlogBox blog={blog} />
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};
