import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { motion } from "framer-motion"
import image_url from '../../common/images';
import { Link } from 'react-router-dom';
export const HeroSection = () => {
    return (
        <div className='hero_top'>
            <div className="hero_main">
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className="open_hero">
                                <div className="ui_top">
                                    <h5>Focus on what matters</h5>
                                    {/* <p>All the charts, datepickers, and notifications in the world can't beat checking off some items on a paper card.</p> */}
                                  <Link to="/shop/reading">  <button className='product_btn'>Shop Productivity</button></Link>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                        <div className="hero_img">
                          
                                
                                    <motion.img whileHover={{
                                 scale:1,
                                 cursor:'pointer',
                                 rotate:'360deg',
                                
                                transition: { duration: 1 },
                            }}src={image_url.herobanner} alt="" ></motion.img>
                               
                           
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
