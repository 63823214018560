import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import image_url from "../../common/images";

export const Meet = () => {
  return (
    <div className="product_bar blogbar meet_bg">
      <Container>
        <Row>
          <Col>
            <div className="blog_row">
              <h5>More About The Product</h5>
              <p>
              With user experience being the ethos, the Revolverr app is built with a focus on functionality. The platform is easy to use and navigable to ease in new users and boost their experience discovering features and building a network. 
              </p>
            </div>
          </Col>
        </Row>
      </Container>
     
        <Container>
          <Row>
            <Col lg={6}>
              <div className="meet_box">
                <img src={image_url.product} alt="" />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                  expedita voluptas culpa sapiente alias molestiae. Numquam
                  corrupti in laborum sed rerum et corporis.{" "}
                </p>
              </div>
            </Col>
            <Col lg={6}>
            <div className="meet_box">
                <img src={image_url.product} alt="" />
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
                  expedita voluptas culpa sapiente alias molestiae. Numquam
                  corrupti in laborum sed rerum et corporis.{" "}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
    
    </div>
  );
};
