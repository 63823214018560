import { combineReducers } from "redux";
import blogReducer from "./blogReducer";
import cartReducer, { formReducer, orderReducer } from "./cartReducer";
import guidelineReducer from "./guidelineReducer";
import merchandiseReducer from "./merchandiseReducer";
import popularReducer from "./popuplarReducer";
import readingReducer from "./readingReducers";

const rootReducer = combineReducers({
  blog: blogReducer,
  merchandise: merchandiseReducer,
  reading: readingReducer,
  popular: popularReducer,
  guideline: guidelineReducer,
  form: formReducer,
  cart: cartReducer,
  order: orderReducer,
});
export default rootReducer;
