import React, { useEffect } from "react";
import { HomeFooter } from "../HomeFooter";
import Homeheader from "../Homeheader";
import BlogSection from "./BlogSection";
import { HeroSection } from "../../LandingPages/Homepage/HeroSection";
import { BannerHome } from "../../LandingPages/Homepage/BannerHome";
import "../landingpage.css";
import { connect } from "react-redux";
import { getBlogs } from "../../../store/actions/blogAction";

const BlogLayout = ({ loading, blogs, getBlogs }) => {
  useEffect(() => {
    getBlogs({});
  }, [getBlogs]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const handleSort = (val) => {
    getBlogs({ sort: val.target.value });
  };
  return (
    <div>
      <Homeheader />
      <HeroSection />
      <BlogSection blogs={blogs || []} handleSort={handleSort} />

      <HomeFooter />
    </div>
  );
};

const mapStateToProps = ({ blog: { loading, blogs } }) => {
  return {
    loading,
    blogs,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBlogs: (payload) => dispatch(getBlogs(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BlogLayout);
